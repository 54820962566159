export default [
   
    {
        url: "/Chapter",
        name: "Chapters",
        icon: "mdi mdi-note-multiple",
        i18n: "Chapters",
        index: 3,
        child: [{
            url: '/ranking',
            name: "Ranking",
            icon: "mdi mdi mdi-format-list-numbers",
            i18n: "Ranking",
            index: 3.1,
        },
            {
                url: '/ranking/geral',
                name: "Repartição Geral",
                icon: "mdi mdi mdi-chart-pie",
                i18n: "Geral",
                index: 3.2,
            },
            {
                url: '/ranking/geral',
                name: "Repartição Geral",
                icon: "mdi mdi mdi-chart-pie",
                i18n: "Arquitetura",
                index: 3.3,
            },
            {
                url: '/ranking/geral',
                name: "Repartição Geral",
                icon: "mdi mdi mdi-chart-pie",
                i18n: "Gramado",
                index: 3.4,
            },
            {
                url: '/ranking/geral',
                name: "Repartição Geral",
                icon: "mdi mdi mdi-chart-pie",
                i18n: "Topografia",
                index: 3.4,
            },
            {
                url: '/ranking/geral',
                name: "Repartição Geral",
                icon: "mdi mdi mdi-chart-pie",
                i18n: "Ilumination",
                index: 3.4,
            }
       ]
    }
]